<template>
  <v-app  v-scroll="onScroll">
   <v-app-bar
      
      app
     
      fixed
    >
 <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up">
 </v-app-bar-nav-icon>

 <v-avatar>
  <img :src="require('./assets/logoV2.png')"/>

 </v-avatar>

      <v-toolbar-title> <h1  class="ml-3 text-center" cols="12">
                                     <span  class="font-weight-black">
                                       O'RUN
                                    </span>
 
                                    
                                </h1></v-toolbar-title>
      <v-spacer class="hidden-sm-and-down"></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">

      <v-btn  to="#download"  text class="ma-md-and-up-3 font-weight-bold">
        <v-icon class="mr-3 mr-xs-0">mdi-download</v-icon>
        <span class="hidden-sm-and-down">Téléchargement</span>
      </v-btn>
   <v-btn v-if="false" to="#pricing" text class="ma-md-and-up-3  font-weight-bold">
        <v-icon class="mr-3 mr-xs-0">mdi-currency-eur</v-icon>
        <span class="hidden-sm-and-down">Tarification</span>
      </v-btn>
        <v-btn  to="#about" text class="ma-md-and-up-3  font-weight-bold">
           <v-icon class="mr-3 mr-xs-0">mdi-information-outline</v-icon>
        <span class="hidden-sm-and-down">A propos</span>
      </v-btn>

</v-toolbar-items>

    </v-app-bar>
     <v-navigation-drawer
      v-model="drawer"
    app
  
    
    >
      <v-list
        nav
        dense
      > 
       <v-list-item  to="#download">
            <v-list-item-title>Téléchargement</v-list-item-title>
          </v-list-item>
       
    
       <v-list-item to="#about">
            <v-list-item-title>A propos</v-list-item-title>
          </v-list-item>

      </v-list>
    </v-navigation-drawer>
    <!--<v-app-bar
      app
      color="transparent"
      dark
    >
    <v-avatar><img :src="require('./assets/iconORun.png')" /></v-avatar>
      <div class="d-flex align-center">
      O-Run

      </div>

      <v-spacer></v-spacer>

      
    </v-app-bar>-->

    <v-main :style="{'position': 'absolute'}" fill-height class="text-center">

      <Home :onIntersect="onIntersect"/>
      <What />
        <Discover  />
    
        <Download />
        <About />
        <Evolutions />
         <v-container>
           <h2 id="testing" class="mb-7 font-weight-black text-uppercase">Je veux tester !</h2>
           <p class="text-center">
             Ca tombe bien ! O'Run permet, via un compte "individuel", de créer des évènements et d'y ajouter des balises. Ainsi vous pouvez tester l'application gratuitement et sans abonnement.
            
           </p>
         </v-container>
        <Pricing v-if="false" />
        <Mentions />
        <v-bottom-sheet v-model="sheet">
          <v-sheet class="text-center">
              
                            <img class="ma-2" width="170" target="_blank" href="https://play.google.com/store/apps/details?id=com.sbd.orun"  :src="require('./assets/google-play-badge.png')" />
                           
                        
                 
                       
                      
                               <img  target="_blank" href="https://apps.apple.com/us/app/o-run/id1560195487#?platform=iphone" class="ma-2" width="170" :src="require('./assets/appstore.png')" />
                            
               
          </v-sheet>     
    </v-bottom-sheet>
    </v-main>
    
  </v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
import Home from './components/Home';
import Discover from './components/Discover';
import Download from './components/Download';
import What  from './components/What';
import Mentions  from './components/Mentions';
import About from './components/About';
import Evolutions from './components/Evolutions';
import Pricing from './components/Pricing';
export default {
  name: 'App',

  components: {
    Home, Discover,  Download, Mentions, What, About, Evolutions, Pricing
  },
 
  methods: {
    onScroll(){
      if (window.pageYOffset > 60 && this.intersecting) {
        this.dark = true;
      } else {
        this.dark = false;
      }
      
    },
    onIntersect (entries) {
        // More information about these options
        // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
       
        this.intersecting = entries[0].isIntersecting;
        
      }
  },
  mounted() {
    this.$root.$on('showDownload', () => {
      this.sheet = true;
    });
  },
  data: () => ({
    dark: false,
    sheet: false,
    drawer: false,
    intersecting: false
  }),
};
</script>
<style scoped>

</style>