<template>
    <v-row no-gutters class="text-center" >
                        <v-container class="d-flex flex-column" fluid  fill-height text-center :style="{'justify-content': 'center'}">
                           <h2 class="pb-5  titleP"> Solution numérique pour parcours d'orientation </h2>
                                           <v-btn href="#video" color="#1E4C7C" dark  rounded>Voir la vidéo</v-btn>
                           </v-container>
                  
    </v-row>

</template>
<script>
export default {
   
}
</script>
<style scoped>
.titleP {
    font-weight: 800;
    font-size: 3.5rem;
    line-height: 96%;
    letter-spacing: -0.05em;
    margin-block-end: 2rem;
    color: rgb(0, 26, 58);
}
</style>