<template>
    <v-container>
                 <h2 id="pricing" class="mb-7 font-weight-black text-uppercase">Tarification</h2>

<p>
 <v-radio-group label="Facturation :" v-model="billingPeriod" row>
 <v-radio
       
        label="Mensuelle"
        value="monthly"
      ></v-radio>
 <v-radio
       
        label="Annuelle"
        value="yearly"
      ></v-radio>
 </v-radio-group>
</p>
               <!-- <iframe v-show="iframeLoaded" :style="{'border':'none'}" @load="loaded" ref="iframe" width="100%" height="500" id="pricingEmbed" src="https://manager.orun.app/#/subscriptions/rkrkrr/add?hideHeader&hideNavbar" />-->

            <div class="d-flex justify-center flex-wrap">
              <v-card
                v-for="(p, i) of products"
                class="ma-5 rounded-xl cardPrice"
                :key="'card_' + i"
                height="300"
                 max-width="200"
                min-width="200"
              >
   <v-card-title color="#26c6da" class="title fontBebas">
                  {{ p.name }}
                </v-card-title>
                <v-divider />
                <v-card-text  class="text-center">
                  <p class="text-center font-weight-bold priceArea fontBebas">
                    <span class="price d-block"
                      > {{ Number(p.prices[billingPeriod]).toFixed(2) }} €  </span>
                    <span>TTC / {{ billingPeriod == 'monthly' ? 'mois': 'an' }}</span>
                   
                  </p>
                  <ul>
                      <li v-for="(f,i) of p.features" :key="'features_'+i" v-html="f">
                          
                      </li>
                    </ul>

                
                </v-card-text>
              <v-card-actions :style="{'width': '100%', 'position':'absolute', 'bottom': 0}" class="justify-center">
                    <v-btn text href="https://manager.orun.app/#/subscriptions/add">Souscrire</v-btn>
                </v-card-actions>
              </v-card>
              <v-card
                class="ma-5 rounded-xl cardPrice"
                
                height="300"
                 min-width="200"
                 max-width="200"
              >
 <v-card-title color="#26c6da" class="title fontBebas">
                  CUSTOM
                </v-card-title>
           
               <v-divider />
                <v-card-text class="text-center">
                     <p class="text-center font-weight-bold priceArea fontBebas">
                    <span class="price d-block"
                      > 
                      sur devis
                    </span>
                     </p>
                     Pour les parcours permanents et les évènements ponctuels comme des courses.
                </v-card-text>
                <v-card-actions :style="{'width': '100%', 'position':'absolute', 'bottom': 0}" class="justify-center">
                 
                    <v-btn text  href="mailto:simon+orun@blum.dev?object=Demande de devis&body=Veuillez décrire votre besoin (durée, caractéristique de votre organisation,...)">Souscrire</v-btn>
                </v-card-actions>
              </v-card>
              </div>


                     <p class="text-justify">
                         <sup class="font-weight-bold">*</sup> entrainement est un évènement ouvert uniquement aux membres de l'association. O'run permet d'ajouter 10 invités à un entrainement.
                         
                     </p>
                <p>
                     <sup class="font-weight-bold">**</sup> une course est un évènement ouvert aux externes dans la limite de 100 participants cumulés maximum.
                </p>



    
                <p class="text-justify" >
                    O'Run propose aussi la fourniture de tags NFC et iBeacons (en direct du fournisseur afin de proposer des prix compétitifs).
                    O'Run peut aussi créer vos circuits sur le Manager et gérer l'installation sur le terrain. <a href="mailto:simon+orun@blum.dev?subject=Devis Orun">Contactez-nous afin d'obtenir un devis.</a></p>
    </v-container>
</template>
<script>
export default {
    mounted() {
        this.iframeLoaded = false;
    },  
    data: () => ({
        tab: null,
        billingPeriod: 'yearly',
        products: [
            {name: 'Small', prices: {monthly: 14.90 , yearly:  159.90}, features: ['1 entrainements<sup>*</sup> par semaine', '1 course<sup>**</sup> par trimestre' ] } ,
            {name: 'Medium', prices: {monthly: 24.90 , yearly:  279.90}, features: ['3 entrainements<sup>*</sup> par semaine', '1 course<sup>**</sup> par trimestre'] },
             {name: 'Large', prices: {monthly: 34.90 , yearly:  399.90}, features: ['entrainements illimités<sup>*</sup>', '1 course<sup>**</sup> par semaine'] }
        ],
        iframeLoaded: false,
    }),
    methods: {
        loaded: function() {

            this.iframeLoaded = true;
        }
    }
}
</script>
<style >
#pricingEmbed header {
    display: none;
}
.cardPrice .title {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  justify-content: center;
}
.priceArea .price {
  font-weight: bold;
  color: black;
  font-size: 30px;
}
</style>